export const GiftingType = {
  None: {
    id: 0,
    name: 'Not Gifting',
    code: 'None',
    price: 0,
  },
  ECard: {
    id: 1,
    name: 'E-Card',
    code: 'ECard',
    price: 0.0,
  },
  GiftBox: {
    id: 2,
    name: 'Gift Box',
    code: 'GiftBox',
    price: 15.0,
  },
  // Supplementary: {
  //     id: 3,
  //     name: "Supplementary plate",
  //     code: "Supplementary",
  //     price: 60.00
  // }
};
