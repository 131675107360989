import { Address } from '~/dataModels/Checkout/Checkout';

export const KenticoEmptyFields = {
  RichText: '<p><br></p>',
};
export const DefaultManufactureLaterAddress: Address = {
  unit: '16/Level 3',
  building: '',
  suburb: 'Parnell',
  street: '125 The Strand',
  postCode: '1010',
  city: 'AUCKLAND',
  fullAddress: 'Unit 16, Level 3, 125 The Strand, Parnell, AUCKLAND 1010',
  isManualAddress: true,
};
export const OrderTypes = {
  New: 'New',
  Redesign: 'Redesign',
};
export const PlateSizes = {
  Regular: {
    name: 'Regular',
    width: 360,
    height: 125,
  },
  Graphic: {
    name: 'Graphic',
    width: 360,
    height: 155,
  },
  Slim: {
    name: 'Slim',
    width: 360,
    height: 100,
  },
  European: {
    name: 'European',
    width: 520,
    height: 112,
  },
  Motorbike: {
    name: 'Motorbike',
    width: 260,
    height: 100,
  },
  Large: {
    name: 'Large',
    width: 440,
    height: 112,
  },
  Japanese: {
    name: 'Japanese',
    width: 330,
    height: 165,
  },
  American: {
    name: 'American',
    width: 305,
    height: 153,
  },
};

export const ButtonTypes = {
  Primary: 'primary',
  Secondary: 'secondary',
  Alternate: 'alternate',
  DarkModeSecondary: 'darkModeSecondary',
};

export const MessagePlateLocations = {
  TopAndBottom: 'top_and_bottom',
  BottomOnly: 'bottom_only',
  TopOnly: 'top_only',
};
export const PlateSizeCodes = {
  Regular: 4,
  Graphic: 1,
  Slim: 2,
  European: 13,
  Motorbike: 3,
  Large: 14,
  Japanese: 15,
  American: 16,
};

export const PlateDesignFormats = {
  Fixed: 'Fixed',
  Flexi: 'Flexi',
  Premium3: 'Premium3',
  Premium2: 'Premium2',
};
export const DefaultCombination = {
  Car: 'ABC123',
  Motorbike: 'ABC12',
  Trailer: 'ABC12',
};

export const PaymentOptions = {
  CreditCard: 1,
  Account2Account: 2,
  Afterpay: 3,
  DealerPaymentRequest: 5,
  Gem: 6,
};

export const S3BucketBaseUrl =
  'https://kiwiplates-marketplace-photos.s3.ap-southeast-2.amazonaws.com';

export const S3BucketFileEditUrl =
  'https://s3-ap-southeast-2.amazonaws.com/kiwiplates-marketplace-photos';

export const S3BucketWebBaseUri =
  'http://kiwiplates-marketplace-photos.s3-website-ap-southeast-2.amazonaws.com';

export const FeatureFlag = {
  EnableEmailValidation: true,
};

export const GA4Event = {
  AddToCart: 'add_to_cart',
  ViewItem: 'view_item',
  Purchase: 'GA4_purchase',
  Search: 'search',
  PlateSuggestions: 'plate_suggestion',
};
export const VOSAFileSizeLimit = 2097152;

export const MininumDirverLicenseAge = 16;

export const ReCaptchaThreshold = 0.49;

export const NameRegex = /^[a-zA-Z'-.\s]+$/;

export const EmailRegex = /\S+@\S+\.\S+/;

export const PhoneRegex = /^\+?[\d\-\s]+$/;

export const PersonalDetailsSection = {
  OwnershipType: 'ownership-type',
  Details: 'details',
  Address: 'address',
  Liscense: 'liscense',
  VehicleRegistration: 'vehicle-registration',
};

export const ReviewOrderSection = {
  PaymentOptions: 'payment-options',
  OrderDetails: 'order-details',
};

export const FullNameRegex =
  /^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/;

export const KpiStatusMapping = {
  Pending: 'Unpaid',
  Unpaid: 'Unpaid',
  InProgress: 'In Progress',
  Paid: 'Paid',
  Processed: 'Downloaded to Licensys',
  Manufactured: 'Order Completed',
  Cancelling: 'Cancelling',
  Approved: 'Paid',
  Authorised: 'Paid',
  Expired: 'Cancelled',
  Cancelled: 'Cancelled',
  PendingPaymentRequest: 'Unpaid',
  LayByPendingAuth: 'LayBy Pending Auth',
  LayByInProgress: 'LayBy In Progress',
  LayBySuspended: 'LayBy Suspended',
  LayByAuthorised: 'LayBy Authorised',
  Refund: 'Cancelled',
};

export const KpiDeliveryMapping = {
  OnRequest: 'Dealer to Request',
  Individual: 'Plates Being Made',
  Company: 'Plates Being Made',
  PreManufacture: 'Plates Being Made',
  PreManufactureCompany: 'Plates Being Made',
};

export const LeadGenStatus = {
  Expired: 'Expired',
  Reserved: 'Reserved',
  UnReserved: 'UnReserved',
  ToBeNotify: 'ToBeNotify',
  Notified: 'Notified',
};

export const LeadGenType = {
  Reservation: 'Reservation',
  Notification: 'Notification',
};

export const LeadSource = {
  SignupBanner: 'Signup Banner',
  Reservation: 'Reservation',
  Notification: 'Notification',
};

export const CombinationReason = {
  isLeadgenReserved: 'COMBINATION_IS_LEADGEN_RESERVED',
};

export const BadgeTransactionType = {
  All: 'All',
  New: 'NewOnly',
  Redesign: 'RedesignOnly',
};

export const DELETE_BASKETLINE = 'DELETE_BASKETLINE';
export const ADD_BASKETLINE = 'ADD_BASKETLINE';
export const ADD_BASKETATTRIBUTES = 'ADD_BASKETATTRIBUTES';
export const ADD_BASKETL_ID = 'ADD_BASKETL_ID';
export const REFRESH_BASKET = 'REFRESH_BASKET';
export const LOADER = 'LOADER';
export const BASKETID = 'basketId';
export const PROMO_CODE = 'promotionCode';

//---------Basket-Attributes--checkout--------------
export const CHECKOUT_APPLICANT_TITLE = 'Checkout.Applicant.Title';
export const CHECKOUT_APPLICANT_FIRST_NAME = 'Checkout.Applicant.FirstName';
export const CHECKOUT_APPLICANT_LAST_NAME = 'Checkout.Applicant.LastName';
export const CHECKOUT_APPLICANT_COMPANY_NAME = 'Checkout.Applicant.CompanyName';
export const CHECKOUT_APPLICANT_STREET_ADDRESS_FULL =
  'Checkout.Applicant.StreetAddressFull';
export const CHECKOUT_APPLICANT_CITY = 'Checkout.Applicant.City';
export const CHECKOUT_APPLICANT_POST_CODE = 'Checkout.Applicant.PostCode';
export const CHECKOUT_APPLICANT_EMAIL = 'Checkout.Applicant.Email';
export const CHECKOUT_APPLICANT_PHONE = 'Checkout.Applicant.Phone';
export const CHECKOUT_APPLICANT_ADDRESS1 = 'Checkout.Applicant.Address1';
export const CHECKOUT_APPLICANT_ADDRESS2 = 'Checkout.Applicant.Address2';
export const CHECKOUT_APPLICANT_ADDRESS3 = 'Checkout.Applicant.Address3';

export const CHECKOUT_DELIVERY_MAKE_NOW = 'Checkout.Delivery.MakeNow';
export const CHECKOUT_DELIVERY_OWNERSHIP_TYPE =
  'Checkout.Delivery.OwnershipType';
export const CHECKOUT_DELIVERY_LICENCE_NO = 'Checkout.Delivery.LicenceNo';
export const CHECKOUT_DELIVERY_DOB = 'Checkout.Delivery.DOB';
export const CHECKOUT_DELIVERY_EXISTING_PLATE =
  'Checkout.Delivery.ExistingPlate';
export const CHECKOUT_DELIVERY_VEHICLE_MAKE = 'Checkout.Delivery.VehicleMake';
export const CHECKOUT_DELIVERY_VEHICLE_MODEL = 'Checkout.Delivery.VehicleModel';
export const CHECKOUT_DELIVERY_TITLE = 'Checkout.Delivery.Title';
export const CHECKOUT_DELIVERY_FIRST_NAME = 'Checkout.Delivery.FirstName';
export const CHECKOUT_DELIVERY_LAST_NAME = 'Checkout.Delivery.LastName';
export const CHECKOUT_DELIVERY_COMPANY_NAME = 'Checkout.Delivery.CompanyName';
export const CHECKOUT_DELIVERY_STREET_ADDRESS_FULL =
  'Checkout.Delivery.StreetAddressFull';
export const CHECKOUT_DELIVERY_CITY = 'Checkout.Delivery.City';
export const CHECKOUT_DELIVERY_POST_CODE = 'Checkout.Delivery.PostCode';
export const CHECKOUT_DELIVERY_COMPANY_NUMBER =
  'Checkout.Delivery.CompanyNumber';
export const CHECKOUT_DELIVERY_ADDRESS1 = 'Checkout.Delivery.Address1';
export const CHECKOUT_DELIVERY_ADDRESS2 = 'Checkout.Delivery.Address2';
export const CHECKOUT_DELIVERY_ADDRESS3 = 'Checkout.Delivery.Address3';
export const CHECKOUT_DELIVERY_EMAIL = 'Checkout.Delivery.Email';
export const CHECKOUT_DELIVERY_PHONE = 'Checkout.Delivery.Phone';
export const CHECKOUT_DELIVERY_MAKE = 'Checkout.Delivery.Make';
export const CHECKOUT_DELIVERY_MODEL = 'Checkout.Delivery.Model';
export const CHECKOUT_DELIVERY_VEHICLE_OWNER_NAME =
  'Checkout.Delivery.VehicleOwnerName';

export const CHECKOUT_OWNER_TITLE = 'Checkout.Owner.Title';
export const CHECKOUT_OWNER_FIRST_NAME = 'Checkout.Owner.FirstName';
export const CHECKOUT_OWNER_LAST_NAME = 'Checkout.Owner.LastName';
export const CHECKOUT_OWNER_COMPANY_NAME = 'Checkout.Owner.CompanyName';
export const CHECKOUT_OWNER_STREET_ADDRESS_FULL =
  'Checkout.Owner.StreetAddressFull';
export const CHECKOUT_OWNER_CITY = 'Checkout.Owner.City';
export const CHECKOUT_OWNER_POST_CODE = 'Checkout.Owner.PostCode';
export const CHECKOUT_OWNER_EMAIL = 'Checkout.Owner.Email';
export const CHECKOUT_OWNER_PHONE = 'Checkout.Owner.Phone';
export const CHECKOUT_OWNER_ADDRESS1 = 'Checkout.Owner.Address1';
export const CHECKOUT_OWNER_ADDRESS2 = 'Checkout.Owner.Address2';
export const CHECKOUT_OWNER_ADDRESS3 = 'Checkout.Owner.Address3';
export const CHECKOUT_OWNER_BIRTH_DATE = 'Checkout.Owner.BirthDate';
export const CHECKOUT_OWNER_PLATES_OWNED = 'Checkout.Owner.PlatesOwned';

export const CHECKOUT_GIFTING_TYPE = 'Checkout.GiftingType';
export const CHECKOUT_GIFTING_DETAILS_TO = 'Checkout.GiftingDetails.To';
export const CHECKOUT_GIFTING_DETAILS_FROM = 'Checkout.GiftingDetails.From';
export const CHECKOUT_GIFTING_DETAILS_MESSAGE =
  'Checkout.GiftingDetails.Message';
export const CHECKOUT_GIFTING_DETAILS_IS_FOR_ME =
  'Checkout.GiftingDetails.IsForMe';
export const CHECKOUT_GIFTING_DELIVERY_ADDRESS_STREET =
  'Checkout.GiftboxDeliveryAddress.Address3';
export const CHECKOUT_GIFTING_DELIVERY_ADDRESS_BUILDING =
  'Checkout.GiftboxDeliveryAddress.Address1';
export const CHECKOUT_GIFTING_DELIVERY_ADDRESS_UNIT =
  'Checkout.GiftboxDeliveryAddress.Address2';
export const CHECKOUT_GIFTING_DELIVERY_ADDRESS_SUBURB =
  'Checkout.GiftboxDeliveryAddress.Suburb';
export const CHECKOUT_GIFTING_DELIVERY_ADDRESS_CITY =
  'Checkout.GiftboxDeliveryAddress.City';
export const CHECKOUT_GIFTING_DELIVERY_ADDRESS_POSTCODE =
  'Checkout.GiftboxDeliveryAddress.PostCode';
export const CHECKOUT_GIFTING_DELIVERY_ADDRESS_FULLADDRESS =
  'Checkout.GiftboxDeliveryAddress.FullAddress';
export const CHECKOUT_GIFTING_DELIVERY_ADDRESS_IS_MANUAL_ADDRESS =
  'Checkout.GiftboxDeliveryAddress.IsManualAddress';

export const CHECKOUT_DEALER_ADDRESS_STREET = 'Checkout.DealerAddress.Address3';
export const CHECKOUT_DEALER_ADDRESS_BUILDING =
  'Checkout.DealerAddress.Address1';
export const CHECKOUT_DEALER_ADDRESS_UNIT = 'Checkout.DealerAddress.Address2';
export const CHECKOUT_DEALER_ADDRESS_SUBURB = 'Checkout.DealerAddress.Suburb';
export const CHECKOUT_DEALER_ADDRESS_CITY = 'Checkout.DealerAddress.City';
export const CHECKOUT_DEALER_ADDRESS_POSTCODE =
  'Checkout.DealerAddress.PostCode';
export const CHECKOUT_DEALER_ADDRESS_IS_MANUAL_ADDRESS =
  'Checkout.DealerAddress.IsManualAddress';
export const CHECKOUT_DEALER_DETAIL_DEALERID = 'Checkout.DealerDetail.DealerId';
export const CHECKOUT_DEALER_DETAIL_SALESPERSONID =
  'Checkout.DealerDetail.SalesPersonId';
export const CHECKOUT_DEALER_DETAIL_DEALERNAME =
  'Checkout.DealerDetail.DealerName';
export const CHECKOUT_DEALER_DETAIL_SALESPERSONNAME =
  'Checkout.DealerDetail.SalesPersonName';
export const CHECKOUT_DEALER_DETAIL_SALESPERSONEMAIL =
  'Checkout.DealerDetail.SalesPersonEmail';
export const CHECKOUT_DEALER_DETAIL_DISTRIBUTIONID =
  'Checkout.DealerDetail.DistributionId';

export const CHECKOUT_IS_ACCEPT_STATEMENT = 'Checkout.IsAcceptStatement';
export const CHECKOUT_IS_DEALER_ORDER = 'Checkout.IsDealerOrder';
export const CHECKOUT_IS_GIFTING = 'Checkout.IsGifting';
export const CHECKOUT_IS_GIFT = 'Checkout.IsGift';
export const CHECKOUT_MANUFACTURE_NOW = 'Checkout.ManufactureNow';
export const CHECKOUT_OWNER_IS_APPLICANT = 'Checkout.OwnerIsApplicant';
export const CHECKOUT_IS_MULTI_PLATE = 'Checkout.IsMultiPlate';
export const CHECKOUT_IS_LAYBY = 'Checkout.IsLayby';
export const CHECKOUT_PAYMENT_TYPE = 'Checkout.PaymentType';
export const CHECKOUT_IS_AUTOPAY = 'Checkout.IsAutopay';
export const CHECKOUT_LAYBY_QUOTE = 'Checkout.LaybyQuote';

export const noEmojiRegex = /^[\p{L}\p{N}\p{P}\p{Z}\n\r\u2028\u2029]*$/u;
